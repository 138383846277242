import classnames from 'classnames'
import React, { ImgHTMLAttributes, FC, CSSProperties } from 'react'

import * as css from './Image.module.scss'

export interface FocalPointCoordinates {
  x: number
  y: number
}
export interface FocalPoint {
  percent: FocalPointCoordinates
  pixel: FocalPointCoordinates
}
export interface ImageDimension {
  w: number
  h: number
}
export type ImageDimensionSize =
  | 600
  | 800
  | 1024
  | 1440
  | 2560
  | 3440
  | 'original'

export type ImageDimensions = Record<ImageDimensionSize, ImageDimension>
export interface Image {
  contentType: string
  focalPoint: FocalPoint
  src: string
  srcSet: string | undefined
  altText: string
  description: Document | undefined
  dimensions: ImageDimensions | undefined
  fileSize: number | undefined
  sizes: string | undefined
  imgProps: {
    alt: string
    sizes: string
    src: string
    srcset: string
  }
}

export interface ImageProps {
  src: string
  alt: string
  srcset?: string
  sizes?: string
  className?: string
  style?: CSSProperties
  round?: boolean
  children?: React.ReactNode
  focalPoint?: FocalPoint
  height?: string
  width?: string
  originalSize?: ImageDimension
  grayscale?: boolean
}

export const Image: FC<ImageProps> = ({
  className,
  round = false,
  src = '',
  srcset = '',
  sizes = '',
  alt,
  children,
  focalPoint,
  grayscale,
}) => {
  const classes = classnames(
    css.imageContainer,
    { [css.imageContainerCircle]: round },
    className
  )
  const filter = grayscale === true ? 'grayscale(1)' : ''
  const imgClasses = round ? css.circle : ''
  if (!src && !srcset) {
    // eslint-disable-next-line no-console
    console.warn('Image is missing src or srcset')
    return <div>No image source</div>
  }
  const objectPosition = `${focalPoint?.percent.x}% ${focalPoint?.percent.y}%`

  const imgProps: ImgHTMLAttributes<HTMLImageElement> = {
    className: imgClasses,
    alt,
    src,
    srcSet: srcset,
    sizes,
    style: {
      objectPosition,
      ...(filter !== '' && { filter }),
    },
  }

  return (
    <div className={classes}>
      <div
        className={classnames(css.imageWrapper, {
          [css.imageWrapperCircle]: round,
        })}
      >
        <img {...imgProps} data-testid="Image" />
      </div>
      {children}
    </div>
  )
}
